@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.confirm-shipping-payment {
    @apply relative pb-12;
}

.confirm-shipping-payment .continue-review {
    @apply absolute bottom-0;
}
