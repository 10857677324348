@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.payment-method-form {
    @apply my-4 px-6 py-2 w-full;
}

.payment-method-form input:not([type="checkbox"]),
.payment-method-form input:not([type="raido"]),
.payment-method-form select{
    @apply rounded border-[#bcbcbc] h-9 font-normal text-xs text-black py-0 px-3;
}

.payment-method-form input:focus,
.payment-method-form select:focus {
    @apply ring-1;
}

.payment-method-form .input-label {
    @apply font-normal text-xs;
}

.payment-method-form .inputs-container {
    @apply flex gap-0 md:gap-5 flex-wrap md:flex-nowrap;
}

.payment-method-form .inputs-container > div {
    @apply w-full;
}

.payment-method-form fieldset {
    @apply mb-4 pb-4 border-b border-skyBlue2;
}

.payment-method-form .action.button {
    @apply rounded border py-2 px-4 w-full text-base disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800 text-base md:text-lg font-normal lg:w-1/2;
}

.payment-method-form .action.save {
    @apply bg-[#83c6d6] border-[#83c6d6] disabled:border-[#e0e0e0];
}

.payment-method-form .action.cancel {
    @apply bg-white border-black disabled:border-[#e0e0e0];
}

.payment-method-form .actions-container {
    @apply flex flex-wrap lg:flex-nowrap mx-auto gap-2;
}
