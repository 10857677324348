@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    .order-confirmation {
        @apply max-w-lg mx-auto box-border;
    }

    .order-confirmation * {
        @apply box-border;
    }

    .order-confirmation .logo-only {
        @apply mx-auto;
    }

    .order-confirmation .items-to-ship {
        @apply w-full mx-0 my-12;
    }

    .order-confirmation .shipping-info {
        @apply my-12 py-8 px-5 border border-skyBlue2;
    }

    .order-confirmation .shipping-info .shipping-to-address {
        @apply mb-0;
    }
}
