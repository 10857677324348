@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  body {
    @apply bg-white;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
  }
  body .doctor-referral {
    @apply bg-white;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    height: 100vh;
  }
  body:has(.doctor-referral),
  #root:has(.doctor-referral) {
    height: 100vh;
  }
  h1, .h1 {
    font-size: 3rem;
    line-height: 3.75rem;
    @apply font-light;
    letter-spacing: -0.06rem;

  }
  h2, .h2 {
    font-size: 2.25rem;
    line-height: 3rem;
    @apply font-light;
    letter-spacing: -0.045rem;
  }
  h3, .h3 {
    font-size: 1.75rem;
    line-height: 2.363rem;
    @apply font-semibold;
    letter-spacing: -0.035rem;
  }
  h4, .h4 {
    font-size: 1.375rem;
    line-height: 2.063rem;
    @apply font-semibold;
    letter-spacing: -0.028rem;
  }
  h5, .h5 {
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-normal uppercase;
    letter-spacing: .113rem;
  }
  .p-lg {
    font-size: 1.125rem;
    line-height: 1.688rem;
    @apply font-normal;
  }
  .p-strong, .p-default-strong {
    font-size: 1rem;
    line-height: 1.5rem;
    @apply font-semibold;
  }
  p, .p, .p-default, .p-md {
    font-size: 1rem;
    line-height: 1.5rem;
    @apply font-normal;
  }
  .p-sm {
    font-size: 0.875rem;
    line-height: 1.313rem;
    @apply font-normal;
  }
  .p-xs {
    font-size: 0.75rem;
    line-height: 1.125rem;
    @apply font-normal;
  }
}

@layer utilities {
  /* Begin Button Utilities */
  .btn {
    @apply cursor-pointer border-2 px-6 my-2 focus:outline-none w-full font-semibold rounded-full;
    height: 2.875rem;
    font-size: 1rem;
  }

  .btn-alt {
    @apply cursor-pointer px-16 py-2 my-2 focus:outline-none font-normal bg-purple1 border-none text-graphite rounded;
  }

  .btn-primary {
    @apply btn bg-white text-teal border-teal hover:bg-teal hover:text-white;
  }

  .btn-primary:disabled {
    @apply btn;
    color: rgba(0, 87, 168, 0.2); /* Colbat-4 */
    background-color: #f4f6fa; /* Colbat-5 */
    border: none;
    cursor: not-allowed;
  }

  .btn-primary-solid {
    @apply btn;
    @apply bg-teal text-white border-teal
  }

  .btn-primary-solid:disabled {
    background-color: rgb(2, 40, 89, 0.2);
    border: none;
    cursor: not-allowed;
  }

  .btn-secondary {
    @apply btn bg-teal text-white border-teal;
    box-shadow: 0 4px 0px 0px #a7dbda;
  }

  .btn-secondary:active,
  .btn-secondary:hover {
    @apply btn bg-aqua2 text-black border-aqua2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .btn-secondary:disabled {
    @apply btn;
    color: #ccced0;
    background-color: #f4f6fa; /* Colbat-5 */
    box-shadow: none;
    cursor: not-allowed;
    border: none;
  }

  .payment-btn {
    @apply cursor-pointer border-2 px-6 my-2 focus:outline-none w-3/4 font-semibold rounded-full bg-teal text-white border-teal hover:bg-aqua2 hover:text-black hover:border-aqua2 hover:shadow-none;
    box-shadow: 0 4px 0px 0px #a7dbda;
  }

  .btn-warning {
    @apply btn bg-goldenrod2 text-black border-goldenrod2;
    -webkit-box-shadow: 0px 2px 0px #ddaf61;
    -moz-box-shadow: 0px 2px 0px #ddaf61;
    box-shadow: 0px 2px 0px #ddaf61;
  }

  .btn-warning:active {
    @apply btn bg-goldenrod2 text-black border-goldenrod2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .btn-warning:hover {
    @apply btn hover:bg-teal border-teal hover:text-white ;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .btn-warning:disabled {
    @apply btn bg-gray3 text-gray1 hover:bg-gray3 hover:text-gray1;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    cursor: not-allowed;
  }
  /* End Button Utilities */

  /* Begin Input Utilities */

  .input {
    @apply py-2 px-6 mb-2 w-full border border-teal font-semibold text-teal focus:outline-none rounded-full bg-white;
    height: 2.875rem;
    font-size: 1rem;
  }
  .input-alt {
    @apply p-2 mb-2 w-full border border-gray-300 text-xs focus:outline-none rounded bg-white disabled:rounded disabled:p-2 disabled:mb-2 disabled:h-auto;
  }

  .input-error {
    @apply input border-canyon3 text-canyon3 bg-canyon3 bg-opacity-10;
    margin-bottom: 0;
  }

  .select-error {
    @apply input border-canyon3 bg-white text-canyon3;
  }

  .input-disabled {
    @apply input;
    @apply border-gray2 text-opacity-10;
  }

  .input-label {
    @apply font-semibold block p-1;
    font-size: 1rem;
    line-height: 1.35rem;
    letter-spacing: 0.02rem;
  }

  .input-asterisk {
    @apply font-semibold text-canyon3;
    font-size: 1rem;
    gap: 0.625rem;
  }

  .brand-input-base {
    @apply text-base leading-snug tracking-wide rounded-full font-normal focus:outline-none text-left border;
  }

  .brand-input-placeholder-base {
    @apply font-light;
  }

  .themed-checkboxes input[type=checkbox] {
    width: 1.3rem;
    height: 1.4rem;
    color: lightgray;
    -webkit-appearance: none;
    background: none;
    border-width: 1px;
    border-color: lightgray;
    outline: 0;
    border-radius: 4px;
    background-color: white;
    transition: background 300ms;
    cursor: pointer;
  }
  .themed-checkboxes input[type=checkbox]::before {
    content: "";
    color: transparent;
    display: block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    border: 5px;
    background-color: transparent;
    background-size: contain;
  }

  .themed-checkboxes input[type=checkbox]:checked {
    @apply bg-teal;
  }

  .themed-checkboxes input[type=checkbox]:checked::before {
    box-shadow: none;
    background-image: url(assets/images/forms-check.svg);
    background-repeat: no-repeat;
    background-size: 19px;
    background-position: left;
  }

  /* End Input Utilities */

  /* Misc Utilities */

  .box-shadow-light {
    box-shadow: 2px 2px 4px -1px rgba(0,0,0,0.25);
  }

  .box-shadow-heavy {
    box-shadow: 4px 4px 8px -2px rgba(0,0,0,0.75);
  }

  .min-width-sm {
    min-width: 320px;
  }
  .min-height-sm {
    min-height: 320px;
  }
  .min-height-md {
    min-height: 480px;
  }
  .min-height-semi-lg {
    min-height: 624px;
  }
  .min-height-lg {
    min-height: 768px;
  }
}

 /* Removes counter spin buttons on Mozilla number inputs */
 input[type=number]::-webkit-outer-spin-button,
 input[type=number]::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
 }
 input[type=number] {
     -moz-appearance: textfield;
     appearance: textfield;
 }

 /* todo: refactor to use tailwindcss generated class */
 .font-sourceserif4 {
  font-family: 'Source Serif 4', serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
 }
details > summary {
  list-style: none;
  position: relative;
}
details > summary:after {
  content: "\002B";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
details[open] > summary:after {
  content: "\2013";
  position: absolute;
  right: 0;
}
.doctor-referral {
  font-family: 'Inter', serif;
}
.tooltip:hover span {
  @apply visible;
}
.tooltip span {
  @apply absolute rounded bg-white px-4 py-2 text-xs w-64 top-10 left-14 invisible;
}
.tooltip.alt span {
  @apply -left-40
}
.tooltip span::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -10px;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.tooltip.alt span::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: -10px;
  right: 22px;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}